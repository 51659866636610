import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { ChatView, Error as MyError, Loading } from '../components/views';
import { setActiveUserInfo, setAppState, setIsOpenMobileMenu } from '../redux/features/app/appSlice';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../redux/app/hooks';
import { RootState } from '../redux/app/store';

import { Image, makeStyles, Portal, shorthands, tokens } from '@fluentui/react-components';
// import { UserSettingsMenu } from '../components/header/UserSettingsMenu';
import { useChat } from '../libs/hooks';
import { EAppState } from '../redux/features/app/AppState';
import { Breakpoints } from '../styles';

import logo from '../assets/images/LogoNew.svg';
import menu from '../assets/images/Menu.svg';
import close from '../assets/images/Close.svg';
import { jwtDecode } from 'jwt-decode';

import { SplashScreens } from '../components/views/SplashScreens';

export const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        ...shorthands.overflow('hidden'),
        '@media (max-width: 1000px)': {
            height: '100dvh',
        },
    },
    headerMobile: {
        alignItems: 'center',
        backgroundColor: '#fff',
        color: '#000',
        '& h1': {
            paddingLeft: tokens.spacingHorizontalXL,
            display: 'flex',
        },
        height: '80px',
        justifyContent: 'space-between',
        ...shorthands.padding('0', '15px'),
        display: 'none',
        ...Breakpoints.small({
            display: 'flex',
        }),
    },
    persona: {
        marginRight: tokens.spacingHorizontalXXL,
    },
});

interface DecodedToken {
    ver: number;
    jti: string;
    iss: string;
    aud: string;
    iat: number;
    exp: number;
    cid: string;
    uid: string;
    scp: string[];
    auth_time: number;
    sub: string;
    countryCode?: string; // countryCode might be undefined
}

const Home = () => {
    const classes = useClasses();
    const { authState, oktaAuth } = useOktaAuth();
    const dispatch = useDispatch();
    const { activeUserInfo, appState } = useAppSelector((state: RootState) => state.app);
    const { conversations } = useAppSelector((state: RootState) => state.conversations);

    const chat = useChat();

    useEffect(() => {
        const token = oktaAuth.getAccessToken() as string;
        if (token) {
            const decodedToken: DecodedToken = jwtDecode(token);

            if (decodedToken.countryCode && decodedToken.countryCode === 'CN') {
                dispatch(setAppState(EAppState.SigningOut));
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                oktaAuth.signOut();
            }
        }
    }, [oktaAuth]);

    useEffect(() => {
        if (appState === EAppState.SigningOut) {
            return;
        }

        if (Object.keys(conversations).length && appState !== EAppState.Chat) {
            dispatch(setAppState(EAppState.Chat));
        }
    }, [conversations, appState]);

    useEffect(() => {
        if (appState === EAppState.SigningOut) {
            return;
        }

        if (authState?.isAuthenticated && appState === EAppState.SettingUserInfo) {
            oktaAuth
                .getUser()
                .then((info) => {
                    dispatch(
                        setActiveUserInfo({
                            id: info.sub,
                            email: info.preferred_username ?? '',
                            username: `${info.given_name ?? ''} ${info.family_name ?? ''}`.trim(),
                        }),
                    );
                    dispatch(setAppState(EAppState.LoadingChats));
                })
                .catch(() => {
                    dispatch(setAppState(EAppState.ErrorLoadingUserInfo));
                });
        }

        if (authState?.isAuthenticated && appState === EAppState.LoadingChats) {
            void Promise.all([
                chat
                    .loadChats()
                    .then(() => {
                        dispatch(setAppState(EAppState.Chat));
                    })
                    .catch(() => {
                        dispatch(setAppState(EAppState.ErrorLoadingChats));
                    }),
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState?.isAuthenticated, appState]);

    if (!authState) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div>
                {!authState.isAuthenticated && appState === EAppState.SigningOut && (
                    <Loading text="Signing you out..." />
                )}

                {authState.isAuthenticated && activeUserInfo && <Chat classes={classes} />}

                {!authState.isAuthenticated && appState !== EAppState.SigningOut && <SplashScreens />}
            </div>
        </div>
    );
};

const Chat = ({ classes }: { classes: ReturnType<typeof useClasses> }) => {
    const { appState } = useAppSelector((state: RootState) => state.app);
    const dispatch = useAppDispatch();
    const { isOpenMobileMenu } = useAppSelector((state: RootState) => state.app);

    return (
        <div className={classes.container}>
            <Portal>
                <div className={classes.headerMobile}>
                    <div
                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', zIndex: '1001' }}
                        onClick={() => {
                            dispatch(setIsOpenMobileMenu(!isOpenMobileMenu));
                        }}
                    >
                        {isOpenMobileMenu ? (
                            <Image src={close} style={{ maxWidth: '100px', width: '100%' }} fit="center" />
                        ) : (
                            <Image src={menu} style={{ maxWidth: '100px', width: '100%' }} fit="center" />
                        )}
                        <strong style={{ marginLeft: '5px' }}>{isOpenMobileMenu ? 'Close' : 'Menu'}</strong>
                    </div>
                    <div style={{ maxWidth: 170 }}>
                        <Image src={logo} style={{ maxWidth: '100px', width: '100%' }} fit="cover" />
                    </div>
                </div>
            </Portal>
            {appState === EAppState.SettingUserInfo && (
                <>
                    <Loading text={'Hang tight while we fetch your information...'} />
                </>
            )}
            {appState === EAppState.ErrorLoadingUserInfo && (
                <MyError text={'Unable to load user info. Please try signing out and signing back in.'} />
            )}
            {appState === EAppState.ErrorLoadingChats && (
                <MyError text={'Unable to load MaIA. Please try refreshing the page.'} />
            )}
            {appState === EAppState.LoadingChats && <Loading text="MaIA is loading..." />}
            {appState === EAppState.Chat && <ChatView />}
        </div>
    );
};

export default Home;
