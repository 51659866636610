import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IImagesMessage, ImagesState, initialState } from './ImagesState';
import { IGalleryResponse, IImagesPromptData } from '../../../libs/services/ImagesService';

export interface IImagesChatMessage {
    user: IImagesPromptData;
    ai: IImagesMessage[];
}

export const imagesSlice = createSlice({
    name: 'images',
    initialState,
    reducers: {
        setConversationMessage: (state: ImagesState, action: PayloadAction<IImagesChatMessage>) => {
            updateConversation(state, action.payload);
        },
        updateConversationMessage: (state: ImagesState, action: PayloadAction<IImagesChatMessage>) => {
            overwriteLastMessage(state, action.payload);
        },
        updateImagesBotResponseStatus: (state: ImagesState, action: PayloadAction<string>) => {
            state.conversation.botResponseStatus = action.payload;
        },
        updateMessageSuccess: (state: ImagesState, action: PayloadAction<boolean>) => {
            state.conversation.isMessageSuccess = action.payload;
        },
        setGallery: (state: ImagesState, action: PayloadAction<IGalleryResponse | undefined>) => {
            if (action.payload === undefined) {
                state.gallery.items = [];
                state.gallery.page = 0;
                state.gallery.size = null;
                state.gallery.pages = null;
                state.gallery.total = null;
                return;
            }
            const { items, page, size, pages, total } = action.payload;
            state.gallery.items = [...items];
            state.gallery.page = page;
            state.gallery.size = size;
            state.gallery.pages = pages;
            state.gallery.total = total;
        },
        addToGallery: (state: ImagesState, action: PayloadAction<IGalleryResponse | undefined>) => {
            if (action.payload === undefined) {
                return;
            }

            const { items, page, size, pages, total } = action.payload;

            state.gallery.items = [...state.gallery.items, ...items];
            state.gallery.page = page;
            state.gallery.size = size;
            state.gallery.pages = pages;
            state.gallery.total = total;
        },
        updateFavorite(state: ImagesState, action: PayloadAction<{ index: number; favorite: boolean }>) {
            const { index, favorite } = action.payload;
            if (state.gallery.items[index]) {
                state.gallery.items[index].favorite = favorite;
            }
        },
        updateFavoriteInConversationMessage: (
            state: ImagesState,
            action: PayloadAction<{ messageIndex: number; image_uuid: string; favorite: boolean }>,
        ) => {
            const { messageIndex, image_uuid, favorite } = action.payload;

            const message = state.conversation.messages[messageIndex];
            if (message) {
                //@ts-expect-error ts-expect-error
                const image = message.ai.find((img) => img.image_uuid === image_uuid);
                if (image) {
                    image.favorite = favorite;
                }
            }
        },
        updateFeedbackInConversationMessage: (
            state: ImagesState,
            action: PayloadAction<{
                messageIndex: number;
                image_uuid: string;
                feedback: 'positive' | 'negative' | null;
            }>,
        ) => {
            const { messageIndex, image_uuid, feedback } = action.payload;

            const message = state.conversation.messages[messageIndex];
            if (message) {
                //@ts-expect-error ts-expect-error
                const image = message.ai.find((img) => img.image_uuid === image_uuid);
                if (image) {
                    image.feedback = feedback;
                }
            }
        },
        updateFeedback(
            state: ImagesState,
            action: PayloadAction<{ index: number; feedback: 'positive' | 'negative' | null }>,
        ) {
            const { index, feedback } = action.payload;
            if (state.gallery.items[index]) {
                state.gallery.items[index].feedback = feedback;
            }
        },
        setPrompt: (state: ImagesState, action: PayloadAction<string>) => {
            state.prompt = action.payload;
        },
        setEnhancingPrompt: (state: ImagesState, action: PayloadAction<string>) => {
            state.enhancingPrompt = action.payload;
        },
        setStyles: (state: ImagesState, action: PayloadAction<string[]>) => {
            state.options.style = action.payload;
        },
        setAngles: (state: ImagesState, action: PayloadAction<string[]>) => {
            state.options.angle = action.payload;
        },
        setModelOptions: (state: ImagesState, action: PayloadAction<Partial<ImagesState['model_options']>>) => {
            state.model_options = { ...state.model_options, ...action.payload };
        },
        setFormat: (state: ImagesState, action: PayloadAction<string>) => {
            state.model_options.aspect_ratio = action.payload;
        },
        resetParameters: (state: ImagesState) => {
            state.options = { style: [], angle: [], others: {} };
            state.model_options = {
                aspect_ratio: '1:1',
                negative_prompt: '',
                model: 'imagen-3.0-generate-001',
                number_of_results: 4,
            };
        },
        cleanConversation: (state: ImagesState) => {
            state.conversation.messages = [];
        },
    },
});

const updateConversation = (state: ImagesState, message: IImagesChatMessage) => {
    state.conversation.messages.push({ ...message });
};

const overwriteLastMessage = (state: ImagesState, message: IImagesChatMessage) => {
    const messagesLength = state.conversation.messages.length;
    if (messagesLength > 0) {
        state.conversation.messages[messagesLength - 1] = { ...message };
    }
};

export const {
    setConversationMessage,
    updateConversationMessage,
    updateImagesBotResponseStatus,
    updateMessageSuccess,
    setGallery,
    addToGallery,
    updateFavorite,
    updateFavoriteInConversationMessage,
    updateFeedbackInConversationMessage,
    updateFeedback,
    setPrompt,
    setEnhancingPrompt,
    setStyles,
    setAngles,
    setModelOptions,
    setFormat,
    resetParameters,
    cleanConversation,
} = imagesSlice.actions;

export default imagesSlice.reducer;
