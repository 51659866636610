import { Button, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React, { useState } from 'react';
import { COPY } from '../../assets/strings';
import { AlertType } from '../../libs/models/AlertType';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { addAlert, clearAlerts, removeAlert } from '../../redux/features/app/appSlice';
import { editConversationInput } from '../../redux/features/conversations/conversationsSlice';
import { Alerts } from '../shared/Alerts';
import { updateUserIsTyping } from './../../redux/features/conversations/conversationsSlice';

import pdf_code_of_use from '../../assets/pdf/Maia_CodeofUse_Nov2024_vdef.pdf';
import pdf_privacy_notice from '../../assets/pdf/MaIA_PrivacyNotice_Nov2024_vdef.pdf';

import { Breakpoints } from '../../styles';
import { useAbortController } from '../../AbortControllerProvider';
import { ImagesStatus } from './ImagesStatus';
import {
    setAngles,
    setEnhancingPrompt,
    setPrompt,
    setStyles,
    updateMessageSuccess,
} from '../../redux/features/images/imagesSlice';
import FilterDialog from './FilterDialog';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { CircularProgress, TextField } from '@mui/material';
import SecondPopover from '../SecondPopover';
import ThirdPopover from '../ThirdPopover';
import FourthPopover from '../FourthPopover';
import { anglesLabels, formatLabels, styleImageWithLabels } from '../../Constants';
import { useImages } from '../../libs/hooks/useImages';

const infoMessage = "Wait for MaIA's response before sending a new message";

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin(0, '72px'),
        ...Breakpoints.small({
            ...shorthands.margin(0, '15px'),
        }),
    },
    typingIndicator: {
        minHeight: '28px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        marginBottom: '8px',
    },
    content: {
        ...shorthands.gap(tokens.spacingHorizontalM),
        display: 'flex',
        position: 'relative',
        flexDirection: 'row',
        width: '100%',
        '& .fui-Textarea:after': {
            display: 'none',
        },
        '& .fui-Textarea': {
            ...shorthands.border('0 !important'),
            paddingBottom: '0',
            ...shorthands.outline('0'),
        },
        '& .fui-Textarea:hover': {
            ...shorthands.border('0', 'solid', 'transparent'),
        },
        '& .fui-Textarea:focus': {
            ...shorthands.border('0', 'solid', 'transparent'),
        },
    },
    input: {
        width: '100%',
    },
    textarea: {
        maxHeight: '230px',
        ...shorthands.padding('12px', '170px', '12px', '12px'),
        ...shorthands.borderRadius('4px', '4px', '4px', '4px'),
        ...shorthands.border('1px', 'solid', '#1A1D20'),
        '&:focus': {
            ...shorthands.borderColor('#9CB0EC'),
            ...shorthands.outline('0'),
        },
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#54616D',
        fontFamily: 'LVMH Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        ...Breakpoints.small({
            fontSize: '12px',
        }),
    },
    essentials: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        position: 'absolute',
        right: '7px',
        bottom: '9px',
        zIndex: '100',
    },
    btn: {
        backgroundColor: '#576DDD',
        ...shorthands.border('0'),
        ...shorthands.borderRadius('50%'),
        maxWidth: '32px',
        minWidth: '32px',
        width: '32px',
        maxHeight: '32px',
        minHeight: '32px',
        height: '32px',
        marginRight: '0',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: '#576DDD',
            opacity: '0.7',
            '&:active': {
                backgroundColor: '#576DDD',
                opacity: '0.7',
            },
        },
        '&:active': {
            backgroundColor: '#576DDD',
            opacity: '0.7',
        },
    },
    btnAttach: {
        color: '#576DDD',
        fontWeight: 400,
        ...shorthands.padding('0'),
        justifyContent: 'end',
        ...Breakpoints.small({
            minWidth: 'fit-content',
        }),
        '& span': {
            ...Breakpoints.small({
                display: 'none',
            }),
        },
        '&[data-fui-focus-visible]': {
            ...shorthands.border('none'),
        },
    },
    btnEnhance: {
        ...shorthands.borderRadius('20px'),
        ...shorthands.borderColor('#9CB0EC'),
        ...shorthands.padding('4px', '16px'),
        maxWidth: '210px',
        color: '#576DDD',
        fontWeight: '400',
        backgroundColor: '#ffffff',
        marginRight: '5px',
        ...Breakpoints.small({
            ...shorthands.borderRadius('50%'),
            minWidth: '32px',
            ...shorthands.padding('0'),
            height: '32px',
        }),
        '&:hover': {
            ...shorthands.borderColor('#576DDD'),
            color: '#fff',
            backgroundColor: '#576DDD',
            '& svg': {
                filter: 'invert(1)',
            },
        },
        '&:hover:active': {
            ...shorthands.borderColor('#576DDD'),
            color: '#fff',
            backgroundColor: '#576DDD',
        },
        '& span': {
            ...Breakpoints.small({
                display: 'none',
            }),
        },
        '&.btnEnhanceActive': {
            ...shorthands.borderColor('#576DDD'),
            color: '#fff',
            backgroundColor: '#576DDD',
            '& svg': {
                filter: 'invert(1)',
            },
        },
    },
    functional: {
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        right: '60px',
        bottom: '10px',
        zIndex: '100',
        '& svg': {
            width: '24px',
            height: '24px',
        },
    },
    dragAndDrop: {
        ...shorthands.border(tokens.strokeWidthThick, ' solid', tokens.colorBrandStroke1),
        ...shorthands.padding('8px'),
        textAlign: 'center',
        backgroundColor: tokens.colorNeutralBackgroundInvertedDisabled,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorBrandForeground1,
        caretColor: 'transparent',
    },
    infoButton: {
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        minWidth: 'auto',
        marginLeft: 'auto',
    },
    menuList: {
        alignItems: 'center',
    },
    menuItem: {
        width: '100%',
        color: '#1A1D20',
        fontSize: '16px',
        '& > span': {
            display: 'flex',
            alignItems: 'center',
            ...shorthands.gap('5px'),
        },
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#576ddd',
        },
        '&:hover:active': {
            backgroundColor: 'transparent',
            color: '#576ddd',
        },
        '&:nth-of-type(2)': {
            ...shorthands.borderBottom('1px', 'solid', '#E0E5F0'),
            ...shorthands.borderTop('1px', 'solid', '#E0E5F0'),
            ...shorthands.borderRadius('0'),
        },
    },
    btnLearn: {
        ...shorthands.borderRadius('20px'),
        ...shorthands.borderColor('#9CB0EC'),
        ...shorthands.padding('4px', '16px'),
        width: '100%',
        maxWidth: '163px',
        color: '#576DDD',
        fontWeight: '400',
        ...shorthands.gap('5px'),
        '&:hover': {
            ...shorthands.borderColor('#576DDD'),
            color: '#fff',
            backgroundColor: '#576DDD',
            '& svg path': {
                fill: '#fff',
            },
        },
        '&:hover:active': {
            ...shorthands.borderColor('#576DDD'),
            color: '#fff',
            backgroundColor: '#576DDD',
            '& svg path': {
                fill: '#fff',
            },
        },
    },
    areaBtns: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        bottom: '10px',
        left: '10px',
    },
    filterBtns: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '10px',
        ...Breakpoints.laptop({
            display: 'none',
        }),
    },
});

const StopIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
            <g>
                <path fill="white" d="M0 0h24v24H0z"></path>
                <path d="M6 7v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1z" fill="white"></path>
            </g>
        </svg>
    );
};

const SubmitIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.14645 3.5607L2 8.70714L1.29289 8.00004L7.64645 1.64648L14 8.00004L13.2929 8.70714L8.14645 3.5607L8.14645 14.3536L7.14645 14.3536L7.14645 3.5607Z"
            fill="white"
        />
    </svg>
);

const AdjustIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5 4.5L11.437 4.5C11.215 3.63739 10.4319 3 9.5 3C8.56808 3 7.78503 3.63739 7.56301 4.5H3.5V5.5H7.56301C7.78503 6.36261 8.56808 7 9.5 7C10.4319 7 11.215 6.36261 11.437 5.5L13.5 5.5V4.5ZM9.5 6C8.94772 6 8.5 5.55228 8.5 5C8.5 4.44772 8.94772 4 9.5 4C10.0523 4 10.5 4.44772 10.5 5C10.5 5.55228 10.0523 6 9.5 6ZM8.43699 11.5C8.21497 12.3626 7.43192 13 6.5 13C5.56808 13 4.78503 12.3626 4.56301 11.5H3.5V10.5H4.56301C4.78503 9.63739 5.56808 9 6.5 9C7.43192 9 8.21497 9.63739 8.43699 10.5L13.5 10.5V11.5L8.43699 11.5ZM7.5 11C7.5 11.5523 7.05228 12 6.5 12C5.94772 12 5.5 11.5523 5.5 11C5.5 10.4477 5.94772 10 6.5 10C7.05228 10 7.5 10.4477 7.5 11Z"
                fill="#576DDD"
            />
        </svg>
    );
};

const EnhanceIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.08362 12.7354L3.62152 12.2972C3.21492 11.9117 3.20439 11.2674 3.59817 10.8688L11.2759 3.09702C11.6585 2.70981 12.2804 2.69964 12.6754 3.07414L13.1375 3.5123C13.5441 3.89782 13.5546 4.54214 13.1608 4.94075L5.48307 12.7125C5.10055 13.0997 4.47859 13.1099 4.08362 12.7354Z"
                fill="#576DDD"
            />
            <path
                d="M7.57693 3.3612C7.5819 3.3475 7.59097 3.33567 7.6029 3.3273C7.61484 3.31894 7.62905 3.31445 7.64363 3.31445C7.6582 3.31445 7.67242 3.31894 7.68435 3.3273C7.69628 3.33567 7.70535 3.3475 7.71032 3.3612L7.77815 3.54653C7.83135 3.69182 7.91559 3.82376 8.02499 3.93316C8.13439 4.04256 8.26633 4.1268 8.41162 4.18L8.59681 4.24783C8.61051 4.2528 8.62234 4.26187 8.63071 4.2738C8.63907 4.28573 8.64356 4.29995 8.64356 4.31452C8.64356 4.3291 8.63907 4.34331 8.63071 4.35525C8.62234 4.36718 8.61051 4.37625 8.59681 4.38122L8.41162 4.44905C8.26633 4.50225 8.13439 4.58648 8.02499 4.69589C7.91559 4.80529 7.83135 4.93723 7.77815 5.08252L7.71032 5.26771C7.70535 5.2814 7.69628 5.29324 7.68435 5.3016C7.67242 5.30997 7.6582 5.31445 7.64363 5.31445C7.62905 5.31445 7.61484 5.30997 7.6029 5.3016C7.59097 5.29324 7.5819 5.2814 7.57693 5.26771L7.5091 5.08252C7.4559 4.93723 7.37167 4.80529 7.26226 4.69589C7.15286 4.58648 7.02092 4.50225 6.87563 4.44905L6.6903 4.38122C6.6766 4.37625 6.66477 4.36718 6.6564 4.35525C6.64804 4.34331 6.64355 4.3291 6.64355 4.31452C6.64355 4.29995 6.64804 4.28573 6.6564 4.2738C6.66477 4.26187 6.6766 4.2528 6.6903 4.24783L6.87563 4.18C7.02092 4.1268 7.15286 4.04256 7.26226 3.93316C7.37167 3.82376 7.4559 3.69182 7.5091 3.54653L7.57693 3.3612Z"
                fill="#E33CF5"
            />
            <path
                d="M11.5322 8.72537C11.5405 8.70254 11.5556 8.68282 11.5755 8.66888C11.5954 8.65494 11.6191 8.64746 11.6433 8.64746C11.6676 8.64746 11.6913 8.65494 11.7112 8.66888C11.7311 8.68282 11.7462 8.70254 11.7545 8.72537L11.8676 9.03425C11.9562 9.2764 12.0966 9.4963 12.279 9.67864C12.4613 9.86098 12.6812 10.0014 12.9233 10.09L13.232 10.2031C13.2548 10.2114 13.2745 10.2265 13.2885 10.2464C13.3024 10.2663 13.3099 10.29 13.3099 10.3142C13.3099 10.3385 13.3024 10.3622 13.2885 10.3821C13.2745 10.402 13.2548 10.4171 13.232 10.4254L12.9233 10.5385C12.6812 10.6271 12.4613 10.7675 12.279 10.9499C12.0966 11.1322 11.9562 11.3521 11.8676 11.5942L11.7545 11.9029C11.7462 11.9257 11.7311 11.9454 11.7112 11.9594C11.6913 11.9733 11.6676 11.9808 11.6433 11.9808C11.6191 11.9808 11.5954 11.9733 11.5755 11.9594C11.5556 11.9454 11.5405 11.9257 11.5322 11.9029L11.4191 11.5942C11.3305 11.3521 11.1901 11.1322 11.0077 10.9499C10.8254 10.7675 10.6055 10.6271 10.3634 10.5385L10.0545 10.4254C10.0316 10.4171 10.0119 10.402 9.99798 10.3821C9.98404 10.3622 9.97656 10.3385 9.97656 10.3142C9.97656 10.29 9.98404 10.2663 9.99798 10.2464C10.0119 10.2265 10.0316 10.2114 10.0545 10.2031L10.3634 10.09C10.6055 10.0014 10.8254 9.86098 11.0077 9.67864C11.1901 9.4963 11.3305 9.2764 11.4191 9.03425L11.5322 8.72537Z"
                fill="#E33CF5"
            />
            <path
                d="M4.8652 3.39236C4.87348 3.36953 4.8886 3.34981 4.90848 3.33587C4.92837 3.32193 4.95207 3.31445 4.97636 3.31445C5.00064 3.31445 5.02434 3.32193 5.04423 3.33587C5.06412 3.34981 5.07923 3.36953 5.08752 3.39236L5.20057 3.70124C5.28923 3.94339 5.42962 4.16329 5.61196 4.34563C5.7943 4.52797 6.0142 4.66836 6.25635 4.75703L6.56499 4.87008C6.58782 4.87836 6.60755 4.89348 6.62149 4.91337C6.63543 4.93325 6.6429 4.95695 6.6429 4.98124C6.6429 5.00552 6.63543 5.02922 6.62149 5.04911C6.60755 5.069 6.58782 5.08411 6.56499 5.0924L6.25635 5.20545C6.0142 5.29411 5.7943 5.4345 5.61196 5.61684C5.42962 5.79918 5.28923 6.01909 5.20057 6.26123L5.08752 6.56988C5.07923 6.59271 5.06412 6.61243 5.04423 6.62637C5.02434 6.64031 5.00064 6.64779 4.97636 6.64779C4.95207 6.64779 4.92837 6.64031 4.90848 6.62637C4.8886 6.61243 4.87348 6.59271 4.8652 6.56988L4.75214 6.26123C4.66348 6.01909 4.52309 5.79918 4.34075 5.61684C4.15841 5.4345 3.93851 5.29411 3.69636 5.20545L3.38748 5.0924C3.36465 5.08411 3.34493 5.069 3.33099 5.04911C3.31705 5.02922 3.30957 5.00552 3.30957 4.98124C3.30957 4.95695 3.31705 4.93325 3.33099 4.91337C3.34493 4.89348 3.36465 4.87836 3.38748 4.87008L3.69636 4.75703C3.93851 4.66836 4.15841 4.52797 4.34075 4.34563C4.52309 4.16329 4.66348 3.94339 4.75214 3.70124L4.8652 3.39236Z"
                fill="#F2B932"
            />
            <path
                d="M11.869 3.5805L12.5862 4.29765C12.6057 4.31718 12.6057 4.34884 12.5862 4.36836L11.2023 5.75219C11.1828 5.77171 11.1512 5.77171 11.1316 5.75219L10.4145 5.03503C10.395 5.0155 10.395 4.98385 10.4145 4.96432L11.7983 3.5805C11.8178 3.56097 11.8495 3.56097 11.869 3.5805Z"
                fill="white"
                stroke="white"
                strokeWidth="0.3"
            />
        </svg>
    );
};

const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.1465 11.3536L0.146484 1.35359L0.853591 0.646484L10.8536 10.6465L10.1465 11.3536Z"
                fill="#030F2B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8536 1.35352L0.853591 11.3535L0.146484 10.6464L10.1465 0.646408L10.8536 1.35352Z"
                fill="#030F2B"
            />
        </svg>
    );
};

const CssTextField = styled(TextField)({
    width: '100%',
    '& label': {
        color: '#54616D',
        top: '-3px',
    },
    '& label.Mui-focused': {
        color: '#1D1D1D',
        top: '0',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#8F8A80',
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: '24px',
        paddingTop: '12.5px',
        paddingBottom: '52.5px',
        background: '#fff',
        height: '100%',
        '& fieldset': {
            borderColor: '#8F8A80',
        },
        '&:hover fieldset': {
            borderColor: '#8F8A80',
        },
        '&.Mui-focused fieldset': {
            borderWidth: '1px',
            borderColor: '#8F8A80',
        },
    },
});

interface ChatInputProps {
    onSubmit: (options: any) => Promise<void>;
}

export const ImagesInput: React.FC<ChatInputProps> = ({ onSubmit }) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { activeUserInfo, alerts } = useAppSelector((state: RootState) => state.app);
    const { isMessageSuccess } = useAppSelector((state: RootState) => state.images.conversation);
    const { prompt, options, enhancingPrompt } = useAppSelector((state: RootState) => state.images);
    const { abortController } = useAbortController();
    const { model_options } = useSelector((state: RootState) => state.images);
    const { popovers } = useSelector((state: RootState) => state.popover);
    const [animationClass, setAnimationClass] = useState('');
    const shadowClassName = popovers[1] ? 'shadow-zindex popover1' : '';
    const shadowClassName2 = popovers[2] ? 'shadow-zindex popover1' : '';
    const shadowClassName3 = popovers[3] ? 'shadow-zindex popover1' : '';
    const images = useImages();

    const [value, setValue] = useState(prompt);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [open, setOpen] = useState(false);
    const [isEnhanced, setIsEnhanced] = useState(false);
    const [enhanceLoading, setEnhanceLoading] = useState(false);

    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

    React.useEffect(() => {
        // Function to focus on the text area
        const focusOnTextArea = () => {
            if (textAreaRef.current) {
                const length = textAreaRef.current.value.length;
                textAreaRef.current.focus();
                textAreaRef.current.setSelectionRange(length, length);
            }
        };

        // If the screen matches the media query, focus on the text area
        const mediaQuery = window.matchMedia('(min-width: 745px)');
        const handleMediaQueryChange = () => {
            if (mediaQuery.matches) {
                focusOnTextArea();
            }
        };

        // Initial focus based on media query
        handleMediaQueryChange();

        // Listen for changes in the media query
        mediaQuery.addListener(handleMediaQueryChange);

        // Clean up event listener on component unmount
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, [selectedId]);

    React.useEffect(() => {
        if (isMessageSuccess) {
            const alertIndex = alerts.findIndex((obj) => obj.message === infoMessage);
            dispatch(removeAlert(alertIndex));
        }
    }, [isMessageSuccess]);

    React.useEffect(() => {
        setValue(prompt);
        if (prompt.length === 0 && isEnhanced) {
            setIsEnhanced(false);
            setEnhanceLoading(false);
        }
    }, [prompt]);

    React.useEffect(() => {
        const chatState = conversations[selectedId];
        setValue(chatState?.disabled ? COPY.CHAT_DELETED_MESSAGE() : chatState?.input);
    }, [conversations, selectedId]);

    const handleSubmit = () => {
        dispatch(clearAlerts());
        const requestData = {
            prompt: prompt,
            options: {
                style: options.style,
                angle: options.angle,
                others: options.others,
            },
            model_options: model_options,
        };
        dispatch(updateMessageSuccess(false));
        setIsSubmitted(true);
        setIsEnhanced(false);
        setEnhanceLoading(false);
        if (prompt.trim() === '') {
            return;
        }
        dispatch(setPrompt(''));
        setValue('');
        selectedId && dispatch(editConversationInput({ id: selectedId, newInput: '' }));
        onSubmit(requestData).catch((error: unknown) => {
            const message = `Error submitting chat input: ${(error as Error).message}`;
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message,
                }),
            );
        });
    };

    const handleEnhance = async (): Promise<void> => {
        if (enhanceLoading) return;

        setEnhanceLoading(true);

        if (isEnhanced) {
            setAnimationClass('fade-out');
            setTimeout(() => {
                dispatch(setPrompt(enhancingPrompt));
                setAnimationClass('fade-in');
                setEnhanceLoading(false);
            }, 500);
            setIsEnhanced(false);
        } else {
            dispatch(setEnhancingPrompt(prompt));
            setAnimationClass('fade-out');
            setTimeout(() => {
                dispatch(setPrompt(' '));
            }, 500);

            const requestData = {
                prompt: prompt,
                options: {
                    style: options.style,
                    angle: options.angle,
                    others: options.others,
                },
                model_options: model_options,
            };

            try {
                setIsEnhanced(true);
                await images.makePromptEnhancedAsync(requestData);
                setAnimationClass('fade-in');
            } finally {
                setEnhanceLoading(false);
            }
        }
    };

    const handleStop = () => {
        abortController('createImageAsync');
        const alertIndex = alerts.findIndex((obj) => obj.message === infoMessage);
        dispatch(removeAlert(alertIndex));
        setIsSubmitted(false);
        setIsEnhanced(false);
        setEnhanceLoading(false);
    };

    return (
        <div className={classes.root}>
            <FilterDialog
                handleClose={() => {
                    setOpen(false);
                }}
                open={open}
            />
            <div className={classes.typingIndicator}>
                <SecondPopover />
                <ThirdPopover />
                <FourthPopover />
                <ImagesStatus />
            </div>
            <Alerts />
            <div className={shadowClassName} id="second-anchor">
                <div className={classes.content}>
                    <div className={`${shadowClassName2} ${classes.functional}`} id="third-anchor">
                        <Button
                            className={classes.btnAttach}
                            appearance="transparent"
                            onClick={() => {
                                setOpen(true);
                            }}
                            title="Fine tune your prompt"
                            aria-label="Fine tune your prompt button"
                            iconPosition="after"
                        >
                            <span>FINE TUNE</span>
                            <AdjustIcon />
                        </Button>
                    </div>
                    <CssTextField
                        label="Write to MaIA"
                        title="Chat input"
                        aria-label="Chat input field. Click enter to submit input."
                        inputRef={textAreaRef}
                        id="chat-input"
                        variant="outlined"
                        multiline
                        maxRows={8}
                        disabled={conversations[selectedId]?.disabled}
                        // className={isDraggingOver ? mergeClasses(classes.dragAndDrop, classes.textarea) : classes.textarea}
                        className={classes.textarea}
                        // value={isDraggingOver ? 'Drop your files here' : value}
                        value={prompt}
                        InputProps={{
                            inputProps: {
                                className: animationClass,
                            },
                        }}
                        onFocus={() => {
                            // update the locally stored value to the current value
                            const chatInput = document.getElementById('chat-input');
                            if (chatInput) {
                                setValue((chatInput as HTMLTextAreaElement).value);
                            }
                            // User is considered typing if the input is in focus
                            if (activeUserInfo) {
                                dispatch(
                                    updateUserIsTyping({
                                        userId: activeUserInfo.id,
                                        chatId: selectedId,
                                        isTyping: true,
                                    }),
                                );
                            }
                        }}
                        onChange={(event) => {
                            const newValue = event.target.value;
                            setValue(newValue);
                            dispatch(setPrompt(newValue));
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && !event.shiftKey) {
                                event.preventDefault();
                                if (value.trim() === '') {
                                    return;
                                }
                                if (value?.length > 0 && (!isSubmitted || isMessageSuccess)) {
                                    handleSubmit();
                                } else {
                                    const shouldAddAlert = !alerts.some((obj) =>
                                        obj.message ? obj.message.includes(infoMessage) : false,
                                    );

                                    if (shouldAddAlert) {
                                        dispatch(
                                            addAlert({
                                                message: infoMessage,
                                                type: AlertType.Info,
                                            }),
                                        );
                                    }
                                }
                            }
                        }}
                        onBlur={() => {
                            // User is considered not typing if the input is not  in focus
                            if (activeUserInfo) {
                                dispatch(
                                    updateUserIsTyping({
                                        userId: activeUserInfo.id,
                                        chatId: selectedId,
                                        isTyping: false,
                                    }),
                                );
                            }
                        }}
                    />
                    <div className={classes.areaBtns}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'end', marginTop: '10px' }}>
                            <Button
                                className={`${isEnhanced ? 'btnEnhanceActive' : ''} ${classes.btnEnhance}`}
                                appearance="transparent"
                                onClick={() => {
                                    void handleEnhance();
                                }}
                                title={isEnhanced ? 'Roll back to original prompt' : 'Enhance prompt'}
                                aria-label={isEnhanced ? 'Roll back to original prompt' : 'Enhance prompt'}
                                iconPosition="after"
                                disabled={!prompt.length || enhanceLoading}
                            >
                                {enhanceLoading ? <CircularProgress size="10px" /> : <EnhanceIcon />}
                                <span>ENHANCE</span>
                            </Button>
                            <div className={classes.filterBtns}>
                                {(options?.style?.[0] || options?.angle?.[0]) && (
                                    <button
                                        onClick={() => {
                                            dispatch(setStyles([]));
                                            dispatch(setAngles([]));
                                        }}
                                        style={{
                                            padding: '4px 16px',
                                            fontSize: '14px',
                                            fontWeight: '300',
                                            color: '#030F2B',
                                            borderRadius: '32px',
                                            backgroundColor: '#FFFFFF',
                                            marginRight: '5px',
                                            cursor: 'pointer',
                                            border: '1px solid #474F6C',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        Delete All
                                    </button>
                                )}
                                {options?.style?.[0] && (
                                    <div
                                        onClick={() => {
                                            dispatch(setStyles([]));
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                            padding: '4px 16px',
                                            fontSize: '14px',
                                            fontWeight: '300',
                                            color: '#030F2B',
                                            borderRadius: '32px',
                                            backgroundColor: '#E0E5F0',
                                            marginRight: '5px',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        <span>{styleImageWithLabels[options.style[0]].label}</span> <CloseIcon />
                                    </div>
                                )}
                                {options?.angle?.[0] && (
                                    <div
                                        onClick={() => {
                                            dispatch(setAngles([]));
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                            padding: '4px 16px',
                                            fontSize: '14px',
                                            fontWeight: '300',
                                            color: '#030F2B',
                                            borderRadius: '32px',
                                            backgroundColor: '#E0E5F0',
                                            marginRight: '5px',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        <span>{anglesLabels[options.angle[0]]}</span> <CloseIcon />
                                    </div>
                                )}
                                {model_options?.aspect_ratio && (
                                    <div
                                        style={{
                                            padding: '4px 16px',
                                            fontSize: '14px',
                                            fontWeight: '300',
                                            color: '#030F2B',
                                            borderRadius: '32px',
                                            backgroundColor: '#E0E5F0',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        {formatLabels[model_options.aspect_ratio]}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={`${shadowClassName3} ${classes.essentials}`} id="fourth-anchor">
                        {!isSubmitted || isMessageSuccess ? (
                            <Button
                                className={classes.btn}
                                title="Submit"
                                aria-label="Submit message"
                                appearance="transparent"
                                icon={<SubmitIcon />}
                                onClick={() => {
                                    if (value?.length > 0) {
                                        handleSubmit();
                                    }
                                }}
                                size="large"
                                disabled={conversations[selectedId]?.disabled || !value?.length}
                            />
                        ) : (
                            <Button
                                className={classes.btn}
                                title="Stop"
                                aria-label="Stop generating"
                                appearance="transparent"
                                icon={<StopIcon />}
                                onClick={() => {
                                    handleStop();
                                }}
                                disabled={conversations[selectedId]?.disabled}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className={classes.controls}>
                <div style={{ textAlign: 'center', margin: 'auto' }}>
                    Before using LVMH MaIA, please read the{' '}
                    <a style={{ color: '#000' }} href={pdf_code_of_use} target="_blank" rel="noreferrer">
                        code of use
                    </a>{' '}
                    and the{' '}
                    <a style={{ color: '#000' }} href={pdf_privacy_notice} target="_blank" rel="noreferrer">
                        privacy notice
                    </a>{' '}
                </div>{' '}
            </div>
        </div>
    );
};
