import React, { createContext, useContext, useState, ReactNode } from 'react';

interface AbortControllerContextType {
    requestControllers: Record<string, AbortController>;
    setController: (requestId: string, controller: AbortController) => void;
    resetController: (requestId: string) => void;
    abortController: (requestId: string) => void;
}

const AbortControllerContext = createContext<AbortControllerContextType>({
    requestControllers: {},
    setController: () => {},
    resetController: () => {},
    abortController: () => {},
});

export const useAbortController = () => useContext(AbortControllerContext);

interface AbortControllerProviderProps {
    children: ReactNode;
}

export const AbortControllerProvider: React.FC<AbortControllerProviderProps> = ({ children }) => {
    const [requestControllers, setRequestControllers] = useState<Record<string, AbortController>>({});

    const setController = (requestId: string, controller: AbortController) => {
        setRequestControllers((prevControllers) => ({
            ...prevControllers,
            [requestId]: controller,
        }));
    };

    const resetController = (requestId: string) => {
        setRequestControllers((prevControllers) => {
            const newControllers = { ...prevControllers };
            //eslint-disable-next-line
            delete newControllers[requestId];
            return newControllers;
        });
    };

    const abortController = (requestId: string) => {
        const controller = requestControllers[requestId];
        controller?.abort();
        resetController(requestId);
    };

    return (
        <AbortControllerContext.Provider
            value={{ requestControllers, setController, resetController, abortController }}
        >
            {children}
        </AbortControllerContext.Provider>
    );
};
