import { IGalleryResponse, IImagesPromptData, ImagesService, IPrompt } from '../services/ImagesService';
import { useOktaAuth } from '@okta/okta-react';
import { useAppDispatch } from '../../redux/app/hooks';
import {
    setConversationMessage,
    setPrompt,
    updateConversationMessage,
    updateImagesBotResponseStatus,
    updateMessageSuccess,
} from '../../redux/features/images/imagesSlice';
import { useAbortController } from '../../AbortControllerProvider';
import { addAlert } from '../../redux/features/app/appSlice';
import { AlertType } from '../models/AlertType';

export const useImages = () => {
    const { oktaAuth } = useOktaAuth();
    const dispatch = useAppDispatch();
    const { setController, requestControllers } = useAbortController();
    const imagesService = new ImagesService();

    const createImageAsync = async (promptData: IImagesPromptData): Promise<string | null> => {
        const accessToken = oktaAuth.getAccessToken() ?? '';
        const idToken = oktaAuth.getIdToken() ?? '';

        dispatch(setConversationMessage({ user: promptData, ai: [] }));
        dispatch(updateImagesBotResponseStatus('MaIA is generating'));

        try {
            if (requestControllers.createImageAsync) {
                requestControllers.createImageAsync.abort();
            }

            const newController = new AbortController();
            setController('createImageAsync', newController);

            const signal = newController.signal;

            const response = await imagesService.createImageAsync(promptData, accessToken, idToken, signal);

            if (response) {
                //@ts-expect-error ts-expect-error
                //eslint-disable-next-line
                if (response?.status?.succeeded === false) {
                    //@ts-expect-error ts-expect-error
                    //eslint-disable-next-line
                    const reasonMessage = response?.status?.reason ?? 'An error occurred while generating the image.';
                    dispatch(addAlert({ message: reasonMessage, type: AlertType.Error }));
                } else {
                    //@ts-expect-error ts-expect-error
                    //eslint-disable-next-line
                    dispatch(updateConversationMessage({ user: promptData, ai: response.images }));
                }
            }
            return null;
        } catch (error) {
            console.error('Error creating image:', error);
            return null;
        } finally {
            dispatch(updateImagesBotResponseStatus(''));
            dispatch(updateMessageSuccess(true));
        }
    };

    const makePromptEnhancedAsync = async (promptData: IImagesPromptData): Promise<string | null> => {
        const accessToken = oktaAuth.getAccessToken() ?? '';
        const idToken = oktaAuth.getIdToken() ?? '';

        try {
            const response = await imagesService.makePromptEnhancedAsync(promptData, accessToken, idToken);

            if (response) {
                dispatch(setPrompt((response as unknown as string) || promptData.prompt));
            }
            return null;
        } catch (error) {
            console.error('Error creating image:', error);
            return null;
        } finally {
            dispatch(updateImagesBotResponseStatus(''));
            dispatch(updateMessageSuccess(true));
        }
    };

    const fetchGallery = async (
        page: string,
        size: string,
        isFavorite: boolean,
    ): Promise<IGalleryResponse | undefined> => {
        const accessToken = oktaAuth.getAccessToken() ?? '';
        const idToken = oktaAuth.getIdToken() ?? '';

        try {
            if (requestControllers.fetchGallery) {
                requestControllers.fetchGallery.abort();
            }

            const newController = new AbortController();
            setController('fetchGallery', newController);

            const signal = newController.signal;

            const galleryData = await imagesService.getGalleryAsync(
                page,
                size,
                isFavorite,
                accessToken,
                idToken,
                signal,
            );

            if (galleryData) {
                return galleryData;
            }

            return undefined;
        } catch (error) {
            console.error('Error fetching gallery:', error);
            return undefined;
        }
    };

    const setImageFeedback = async (
        promptUuid: string,
        imageUuid: string,
        feedback: 'positive' | 'negative' | null,
    ): Promise<void> => {
        const accessToken = oktaAuth.getAccessToken() ?? '';
        const idToken = oktaAuth.getIdToken() ?? '';

        try {
            if (requestControllers.setImageFeedback) {
                requestControllers.setImageFeedback.abort();
            }

            const newController = new AbortController();
            setController('setImageFeedback', newController);

            const signal = newController.signal;

            await imagesService.setImageFeedback(promptUuid, imageUuid, feedback, accessToken, idToken, signal);
        } catch (error) {
            console.error('Error setting image feedback:', error);
        }
    };

    const setImageFavorite = async (promptUuid: string, imageUuid: string, favorite: boolean): Promise<void> => {
        const accessToken = oktaAuth.getAccessToken() ?? '';
        const idToken = oktaAuth.getIdToken() ?? '';

        try {
            if (requestControllers.setImageFavorite) {
                requestControllers.setImageFavorite.abort();
            }

            const newController = new AbortController();
            setController('setImageFavorite', newController);

            const signal = newController.signal;

            await imagesService.setImageFavorite(promptUuid, imageUuid, favorite, accessToken, idToken, signal);
        } catch (error) {
            console.error('Error setting image feedback:', error);
        }
    };

    const fetchPromptByUuid = async (promptUuid: string): Promise<IPrompt | null> => {
        const accessToken = oktaAuth.getAccessToken() ?? '';
        const idToken = oktaAuth.getIdToken() ?? '';

        try {
            if (requestControllers.fetchPromptByUuid) {
                requestControllers.fetchPromptByUuid.abort();
            }

            const newController = new AbortController();
            setController('fetchPromptByUuid', newController);

            const signal = newController.signal;

            const response = await imagesService.getPromptByUuid(promptUuid, accessToken, idToken, signal);

            return response;
        } catch (error) {
            console.error('Error creating image:', error);
            return null;
        } finally {
        }
    };

    return {
        createImageAsync,
        makePromptEnhancedAsync,
        fetchGallery,
        setImageFeedback,
        setImageFavorite,
        fetchPromptByUuid,
    };
};
